import React from "react";
import CommonLayout from "../../layout/CommonLayout";

import * as styles from './about.module.css';

import mary_fatherImg from '../../assets/mary_father.jpg';
import maryA2Img from '../../assets/MaryA2.jpg';
import { Link } from "gatsby";

import { Accordion, Icon } from "semantic-ui-react";





const RequestPresentationButton = () => <>
    <Link to="../contact">
        <div className={styles["reqButton"]} >
            <div className={styles["reqButtonMinorLabel"]} >Click here to</div>
            <div className={styles["reqButtonMajorLabel"]} >Request a presentation!</div>
        </div>
    </Link>
</>

const Education = () => <>
    <h2>&#9818; Education</h2>
    <table className={styles["eduList"]}>
        <tr><td>
            <div>Ed.M. Instructional Technology and Media. 1989.</div>                   
            <div>Teachers College, Columbia University. New York</div>
        </td></tr>
        <tr><td>
            <div>M.A. Teaching of English to Speakers of Other Languages. 1986.</div>
            <div>Teachers College, Columbia University. New York.</div>
        </td></tr>
        <tr><td>M.A. Spanish.  Middlebury College Graduate School of Spanish in Spain. Madrid. 1976.</td></tr>
        <tr><td>B.A. Spanish.  Middlebury College. Middlebury, Vermont. 1974.</td></tr>
    </table>
</>

const PresentationList = () => 
    <table className={styles["presList"]} >

        <colgroup>
            <col style={{ width: '15%' }} />
            <col style={{ width: '85%' }}/>
        </colgroup>
    

        <tbody>
            <tr>
                <th>Date</th>
                <th>Location</th>
            </tr>

            <tr><td><b>Jun 2024</b></td> <td> St. Maarten Library, Dutch Caribbean </td></tr>
            <tr><td><b>May 2024</b></td> <td> Mauthausen Memorial Department, Austrian Ministry of the Interior, Vienna, Austria </td></tr>
            <tr><td><b>Apr 2024</b></td> <td> Avery Street Christian Reformed Church, Windsor, CT </td></tr>
            <tr><td><b>Apr 2024</b></td> <td> Pomfret School, Pomfret, CT </td></tr>
            <tr><td><b>Apr 2024</b></td> <td> Sacramento State University, CA </td></tr>
            <tr><td><b>Apr 2024</b></td> <td> Bucks County Community College, PA </td></tr>
            <tr><td><b>Apr 2024</b></td> <td> Central High School, Philadelphia, PA </td></tr>
            <tr><td><b>Mar 2024</b></td> <td> Woodstock Academy, CT </td></tr>
            <tr><td><b>Feb 2024</b></td> <td> National Association of African American Studies, Austin, TX </td></tr>
            <tr><td><b>Feb 2024</b></td> <td> Central Connecticut State University </td></tr>
            <tr><td><b>Jan 2024</b></td> <td> Black History Studies, London, England </td></tr>

            <tr><td><b>Nov 2023</b></td> <td>  Comité International de Mauthausen, Paris, France</td></tr>
            <tr><td><b>Oct 2023</b></td> <td>  Quinebaug Valley Community College, Danielson, CT</td></tr>
            <tr><td><b>Oct 2023</b></td> <td>  University of Pittsburgh, PA</td></tr>
            <tr><td><b>Oct 2023</b></td> <td>  American Association of University Women, CT</td></tr>
            <tr><td><b>Oct 2023</b></td> <td>  Sacramento State University, CA</td></tr>
            
            <tr><td><b>Sept 2023</b></td> <td>  Texas Oral History Association</td></tr>

            <tr><td><b>Jun 2023</b></td> <td> Oral History Society, Nottingham, England</td></tr>
            <tr><td><b>Jun 2023</b></td> <td> Second Congregational Church, Coventry, CT</td></tr>
            <tr><td><b>May 2023</b></td> <td> HAMEC at Abbington School District, PA</td></tr>
            <tr><td><b>Apr 2023</b></td> <td> Storrs Road Church of Christ, CT</td></tr>
            <tr><td><b>Apr 2023</b></td> <td> HAMEC at Central High School, Philadelphia, PA</td></tr>
            <tr><td><b>Apr 2023</b></td> <td> Trinity College, CT</td></tr>
            <tr><td><b>Apr 2023</b></td> <td> Windham High School, CT</td></tr>
            <tr><td><b>Apr 2023</b></td> <td> Sacramento State University, CA</td></tr>


            <tr><td><b>Mar 2023</b></td> <td> Museums Association of the Caribbean, Bahamas</td></tr>
            <tr><td><b>Feb 2023</b></td> <td> Altrusa International Foundation, CT</td></tr>
            <tr><td><b>Feb 2023</b></td> <td> NAACP, Windham, CT</td></tr>
            <tr><td><b>Feb 2023</b></td> <td> Nat’l Assoc. of African American Studies, Dallas, TX</td></tr>
            <tr><td><b>Feb 2023</b></td> <td> West Indian Social Club of Hartford, CT</td></tr>
            <tr><td><b>Feb 2023</b></td> <td> Comparative and International Education Society, WDC</td></tr>
            <tr><td><b>Feb 2023</b></td> <td> Quinebaug Valley Community College, CT</td></tr>
            <tr><td><b>Feb 2023</b></td> <td> University of Pittsburgh, PA</td></tr>
            <tr><td><b>Jan 2023</b></td> <td> Black History Studies, London, UK</td></tr>
            <tr><td><b>Jan 2023</b></td> <td> ASALH Book Talk, Washington, DC</td></tr>


            <tr><td><b>Jun 2022</b></td> <td>  Caribbean Literary Festival, Bloomfield, CT</td></tr>

            <tr><td><b>Jun 2022</b></td> <td> St. Martin Book Fair, St. Maarten</td></tr>
            <tr><td><b>May 2022</b></td> <td> Caribbean Studies Association, UWI, Jamaica</td></tr> 
            <tr><td><b>May 2022</b></td> <td> Official Book Launch, St. Maarten</td></tr>
            <tr><td><b>Apr 2022</b></td> <td> Eastern Connecticut State University</td></tr>
            <tr><td><b>Apr 2022</b></td> <td> HAMEC at Central High School, Philadelphia, PA</td></tr>
            <tr><td><b>Apr 2022</b></td> <td> Black History Studies,  London, England</td></tr>
            <tr><td><b>Apr 2022</b></td> <td> University of Pittsburgh</td></tr>


            <tr><td><b>Apr 2022</b></td> <td> Sacramento State University, CA</td></tr>

            <tr><td><b>Mar 2022</b></td> <td> Sacramento State University, CA </td></tr>
            <tr><td><b>Mar 2022</b></td> <td> New York University</td></tr>


            <tr><td><b>Feb 2022</b></td> <td> Asbury United Methodist Church, Washington, DC</td></tr>
            <tr><td><b>Feb 2022</b></td> <td> Holocaust Awareness Museum and Education Center (HAMEC), Philadelphia, PA</td></tr>
            <tr><td><b>Feb 2022</b></td> <td> University of Massachusetts, Amherst, MA</td></tr>
            <tr><td><b>Feb 2022</b></td> <td> Black History Studies, London, England</td></tr>
            <tr><td><b>Feb 2022</b></td> <td> Aging with Grace TV, St. Maarten, Dutch Caribbean</td></tr>
            <tr><td><b>Feb 2022</b></td> <td> University of Curaçao, Dutch Caribbean</td></tr>

            <tr><td><b>Oct 2021</b></td> <td>	Afro-American Historical and Genealogical Society, Washington, DC</td></tr>
            <tr><td><b>Oct 2021</b></td> <td>	Oral History Association, TN </td></tr>
            <tr><td><b>Oct 2021</b></td> <td>	Dutch Caribbean Book Club, The Netherlands </td></tr>
            <tr><td><b>Oct 2021</b></td> <td>	London Metropolitan University, England  </td></tr>
            <tr><td><b>Sep 2021</b></td> <td>	Caribbean Lagoon, The Netherlands </td></tr>
            <tr><td><b>Sep 2021</b></td> <td>	Assoc. for the Study of African American Life and History, Washington, DC </td></tr>
            <tr><td><b>Jun 2021</b></td> <td>	Oxford Brookes University, Oxford, England  </td></tr>
            <tr><td><b>Apr 2021</b></td> <td>	Association for the Promotion of Southern African Studies in Austria </td></tr>
            <tr><td><b>Apr 2021</b></td> <td>	The Fessenden School, Boston, MA, USA </td></tr>
            <tr><td><b>Feb 2021</b></td> <td>	Teachers of English to Speakers of Other Languages International Association </td></tr>
            <tr><td><b>Feb 2021</b></td> <td>	Bergen Community College, Paramus, NJ </td></tr>


            <tr><td><b>Jun 2018</b></td> <td>       Seminar on Prisoners of African Descent at Mauthausen Concentration Camp,  Vienna, Austria </td></tr>
            <tr><td><b>2006-2015</b></td> <td>	 	Quinebaug Valley Community College, Danielson, CT, USA  </td></tr>
            <tr><td><b>Oct 2012</b></td> <td>	    Philipsburg Jubilee Library, St. Maarten, Dutch Caribbean  </td></tr>
            <tr><td><b>Dec 2010</b></td> <td>        University of Connecticut, African American Cultural Center, CT, USA  </td></tr>
            <tr><td><b>May 2007</b></td> <td>        Society of Friends of the West Indian Museum of Panama, Panama City, Panama </td></tr>
        </tbody>
    </table>

    // <ul className={styles["presList"]} >

    //     <li><b>Nov 2023</b>  Comité International de Mauthausen, Paris, France</li>
    //     <li><b>Oct 2023</b>  Quinebaug Valley Community College, Danielson, CT</li>
    //     <li><b>Oct 2023</b>  University of Pittsburgh, PA</li>
    //     <li><b>Oct 2023</b>  American Association of University Women, CT</li>
    //     <li><b>Oct 2023</b>  Sacramento State University, CA</li>
         
    //     <li><b>Sept 2023</b>  Texas Oral History Association</li>

    //     <li><b>Jun 2023</b> Oral History Society, Nottingham, England</li>
    //     <li><b>Jun 2023</b> Second Congregational Church, Coventry, CT</li>
    //     <li><b>May 2023</b> HAMEC at Abbington School District, PA</li>
    //     <li><b>Apr 2023</b> Storrs Road Church of Christ, CT</li>
    //     <li><b>Apr 2023</b> HAMEC at Central High School, Philadelphia, PA</li>
    //     <li><b>Apr 2023</b> Trinity College, CT</li>
    //     <li><b>Apr 2023</b> Windham High School, CT</li>
    //     <li><b>Apr 2023</b> Sacramento State University, CA</li>


    //     <li><b> Mar 2023</b> Museums Association of the Caribbean, Bahamas</li>
    //     <li><b> Feb 2023</b> Altrusa International Foundation, CT</li>
    //     <li><b> Feb 2023</b> NAACP, Windham, CT</li>
    //     <li><b> Feb 2023</b> Nat’l Assoc. of African American Studies, Dallas, TX</li>
    //     <li><b> Feb 2023</b> West Indian Social Club of Hartford, CT</li>
    //     <li><b> Feb 2023</b> Comparative and International Education Society, WDC</li>
    //     <li><b> Feb 2023</b> Quinebaug Valley Community College, CT</li>
    //     <li><b> Feb 2023</b> University of Pittsburgh, PA</li>
    //     <li><b> Jan 2023</b> Black History Studies, London, UK</li>
    //     <li><b> Jan 2023</b> ASALH Book Talk, Washington, DC</li>


    //     <li><b>Jun 2022</b>  Caribbean Literary Festival, Bloomfield, CT</li>

    //     <li><b>Jun 2022</b> St. Martin Book Fair, St. Maarten</li>
    //     <li><b>May 2022</b> Caribbean Studies Association, UWI, Jamaica</li> 
    //     <li><b>May 2022</b> Official Book Launch, St. Maarten</li>
    //     <li><b>Apr 2022</b> Eastern Connecticut State University</li>
    //     <li><b>Apr 2022</b> HAMEC at Central High School, Philadelphia, PA</li>
    //     <li><b>Apr 2022</b> Black History Studies,  London, England</li>
    //     <li><b>Apr 2022</b> University of Pittsburgh</li>


    //     <li><b>Apr 2022</b> Sacramento State University, CA</li>

    //     <li><b>Mar 2022</b> Sacramento State University, CA </li>
    //     <li><b>Mar 2022</b> New York University</li>


    //     <li><b>Feb 2022</b> Asbury United Methodist Church, Washington, DC</li>
    //     <li><b>Feb 2022</b> Holocaust Awareness Museum and Education Center (HAMEC), Philadelphia, PA</li>
    //     <li><b>Feb 2022</b> University of Massachusetts, Amherst, MA</li>
    //     <li><b>Feb 2022</b> Black History Studies, London, England</li>
    //     <li><b>Feb 2022</b> Aging with Grace TV, St. Maarten, Dutch Caribbean</li>
    //     <li><b>Feb 2022</b> University of Curaçao, Dutch Caribbean</li>

    //     <li><b>Oct 2021</b>	Afro-American Historical and Genealogical Society, Washington, DC</li>
    //     <li><b>Oct 2021</b>	Oral History Association, TN </li>
    //     <li><b>Oct 2021</b>	Dutch Caribbean Book Club, The Netherlands </li>
    //     <li><b>Oct 2021</b>	London Metropolitan University, England  </li>
    //     <li><b>Sep 2021</b>	Caribbean Lagoon, The Netherlands </li>
    //     <li><b>Sep 2021</b>	Assoc. for the Study of African American Life and History, Washington, DC </li>
    //     <li><b>Jun 2021</b>	Oxford Brookes University, Oxford, England  </li>
    //     <li><b>Apr 2021</b>	Association for the Promotion of Southern African Studies in Austria </li>
    //     <li><b>Apr 2021</b>	The Fessenden School, Boston, MA, USA </li>
    //     <li><b>Feb 2021</b>	Teachers of English to Speakers of Other Languages International Association </li>
    //     <li><b>Feb 2021</b>	Bergen Community College, Paramus, NJ </li>


    //     <li><b>Jun 2018</b>       Seminar on Prisoners of African Descent at Mauthausen Concentration Camp,  Vienna, Austria </li>
    //     <li><b>2006-2015</b>	 	Quinebaug Valley Community College, Danielson, CT, USA  </li>
    //     <li><b>Oct 2012</b>	    Philipsburg Jubilee Library, St. Maarten, Dutch Caribbean  </li>
    //     <li><b>Dec 2010</b>        University of Connecticut, African American Cultural Center, CT, USA  </li>
    //     <li><b>May 2007</b>        Society of Friends of the West Indian Museum of Panama, Panama City, Panama </li>

    // </ul>

    {/* <h3>[Feb presentation title]</h3>
    <ul>
    <li>February 2022 Asbury United Methodist Church, Washington, DC</li>
    <li>February 2022 Holocaust Awareness and Education Center, Philadelphia, PA</li>
    <li>February 2022 University of Massachusetts, Amherst, MA</li>
    <li>February 2022 Black History Studies, London, England</li>
    <li>February 2022 Aging with Grace TV, St. Maarten, Dutch Caribbean</li>
    <li>February 2022 University of Curaçao, Dutch Caribbean</li>
    </ul> */}
;

const PresentationAccordion = () => {

    const panels = [
        {
            key: 'pres-list',
            title: "List of Presentations",

            content: {
                content: (
                    <PresentationList />
                )
            }
        }
    ]

    const presList = [
        
    ]


    return(
        <Accordion styled defaultActiveIndex={0} panels={panels} />
    );
}

const Presentations = () => <>
    <div className={styles["presInfo"]}>
        <h2>PRESENTATIONS</h2>
        <RequestPresentationButton />
        <br />
        <h3>An Afro-Caribbean in the Nazi Era</h3>

        <PresentationAccordion />        
        
    </div>

</>

const ProfessionalInfo = () => <>
     <div className={styles["profInfo"]}>
        <h2> &#9750; Professional Experience</h2>
        <ul>
            <li>2014-2018	Program Administrator and Instructor of English as a Second Language (ESL)
            <br />   Capital Community College,  Hartford, CT.
            </li>
            
            <li>2007-2014	Assistant Professor, International Teaching Assistants Program
            <br />  University of Connecticut.   Storrs, CT.
            </li>
            
            <li>1992-2007	ESL Instructor and Coordinator of Academic Support. 
            <br />  Quinebaug Valley Community College. Willimantic, CT. 
            </li>
            
            <li>1990-1992 	Director, Badalona Branch. 
                <br />Institute of North American Studies. Badalona (Barcelona), Spain. 
            </li>

            <li>1989-1990	Teacher of  English as a Foreign Language.
            <br />Institute of North American Studies.  Barcelona, Spain.
            </li>

            <li>1976-1989 	ESL Instructor in New York City and Madrid, Spain 
            </li>
        </ul>
    </div>
</>


const AboutPage = () => {

    return (

        <CommonLayout>
            <h1 className={styles["pageTitle"]}>About the Author</h1>



            <div className={styles["aboutContainer"]}>

                <img className={styles["aboutImg"]} src={maryA2Img} alt="trees" />


                <div className={styles["aboutContent"]}>

                    <h2 className={styles["authName"]}>Mary Romney-Schaab</h2>
                    <p className={styles["summary"]}>
                        I was born in New York City, but my family is from the Caribbean island nation of St. Maarten.  
                    </p>
                    
                    <p className={styles["summary"]}>
                            With roots in the Caribbean, I am interested in African Diaspora history, oral history, languages, and culture.  A retired educator, I spent over 40 years teaching English as a second or foreign language in Madrid, New York, Barcelona, and Connecticut.  
                    </p>

                    

                    {/* <b>LANGUAGES:</b>
                    <>Bilingual: English/Spanish</> */}

                </div>

            </div>

            <div className={styles["infoCont"]}>
                
                <div>
                    <Education />
                    <ProfessionalInfo />
                </div>
                
                <Presentations />

            </div>
        

        </CommonLayout>
    
    );

}

export default AboutPage;
