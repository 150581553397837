// extracted by mini-css-extract-plugin
export var banner = "CommonLayout-module--banner--2639a";
export var bannerBG = "CommonLayout-module--bannerBG--c4275";
export var bodyBG = "CommonLayout-module--bodyBG--063e4";
export var bodyContainer = "CommonLayout-module--bodyContainer--5d803";
export var bodyContent = "CommonLayout-module--bodyContent--99a00";
export var footerContainer = "CommonLayout-module--footerContainer--1fabe";
export var footerContent = "CommonLayout-module--footerContent--149b6";
export var headerContainer = "CommonLayout-module--headerContainer--34b7c";
export var headerContent = "CommonLayout-module--headerContent--ff478";
export var layoutContent = "CommonLayout-module--layoutContent--d7639";
export var layoutMain = "CommonLayout-module--layoutMain--e4b75";
export var titleName = "CommonLayout-module--titleName--46ca3";