import internal from "stream";


const renewLayout = () => {

};

enum LAYOUT {
    mobile
    , tablet
    , desktop
}

type Dimensions = {
    width: number
    , height: number
}
type WinState = {
    dim: Dimensions
}

const retrieveDimensions = (): Dimensions => { 
    return { 
        width: innerWidth
        , height: innerHeight
    }
}
const retDim = retrieveDimensions;

const getLayoutType = (dim: Dimensions = retDim()): LAYOUT => {
    const wid = dim.width;
    return wid && wid <= 500 ? LAYOUT.mobile
        : wid && wid <= 800 ? LAYOUT.tablet
        : LAYOUT.desktop
    ;

    //return LAYOUT.desktop
}


export {
    LAYOUT
    , Dimensions

    , retrieveDimensions
    , retDim
    , getLayoutType
}
