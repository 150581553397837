import { Link } from 'gatsby';
import React from 'react';

import * as styles from './nav.module.css';


const Nav = () => {

    return (
        <nav>
            <Link className={styles["navItem"]} to="/landing">Home</Link>
            <Link className={styles["navItem"]} to="/book">Book</Link>
            <Link className={styles["navItem"]} to="/about">About the Author</Link>
            <Link className={styles["navItem"]} to="/contact">Contact</Link>
            {/* <Link className={styles["navItem"]} to="">&#128269;</Link> */}
            
        </nav>
    );
}

export default Nav;