// extracted by mini-css-extract-plugin
export var aboutContainer = "about-module--aboutContainer--e84e9";
export var aboutContent = "about-module--aboutContent--eccd3";
export var aboutImg = "about-module--aboutImg--899f6";
export var authName = "about-module--authName--4f234";
export var eduList = "about-module--eduList--3c573";
export var infoCont = "about-module--infoCont--43594";
export var pageTitle = "about-module--pageTitle--68395";
export var presInfo = "about-module--presInfo--2d2f3";
export var presList = "about-module--presList--85595";
export var profInfo = "about-module--profInfo--d4cb2";
export var reqButton = "about-module--reqButton--1d9be";
export var reqButtonMajorLabel = "about-module--reqButtonMajorLabel--13435";
export var reqButtonMinorLabel = "about-module--reqButtonMinorLabel--bd440";
export var summary = "about-module--summary--4ae60";